import * as Handlebars from 'handlebars';

/**
 * Extensions that are available for Handlebars registration
 */
export const handlebarsExtensions: {
  key: string;
  helper: (...args: unknown[]) => unknown;
}[] = [
  { key: 'top', helper: getUpToNItems },
  { key: 'any', helper: arrayHasItems },
  { key: 'first', helper: getFirstItemFromArrayOrObject },
  { key: 'count', helper: countElementsInArray },
];

/**
 * Gets up to N items from an array
 * @param limit Maximum number of items from an array
 * @param arr Array to get items from
 */
function getUpToNItems(limit: unknown, arr: unknown): unknown[] {
  // If any of the checks fail then return empty array
  if (!limit || !arr || typeof limit !== 'number' || !Array.isArray(arr)) {
    return [];
  }

  // If the limit is more than the length then return the original array
  if (limit >= arr.length) {
    return arr;
  }

  // Otherwise return the subset up to limit
  return arr.slice(0, limit);
}

/**
 * Checks whether array has any items in it
 * @param arr Array to check
 */
function arrayHasItems(arr: unknown): boolean {
  return Array.isArray(arr) && arr.length > 0;
}

/**
 * Counts the number of items in an array
 * @param arr Array to count items in
 * @returns Count of elements in array or 0 if item is not an array
 */
function countElementsInArray(arr: unknown): number {
  return Array.isArray(arr) ? arr.length : 0;
}

/**
 * Gets the first item from an array
 * @param arrOrObject Array or object to get item from
 */
function getFirstItemFromArrayOrObject(arrOrObject: unknown): unknown {
  if (arrOrObject === undefined || arrOrObject === null) return undefined;

  let returnItem: unknown = undefined;

  if (Array.isArray(arrOrObject)) {
    returnItem = arrOrObject[0];
  } else if (typeof arrOrObject === 'object') {
    const properties = Object.getOwnPropertyNames(arrOrObject);

    if (properties.length > 0) {
      // @ts-expect-error Bad warning - checking for type before
      returnItem = arrOrObject[properties[0]];
    }
  }

  // If the item type is string then mark it as safe - it could be HTML.
  if (typeof returnItem === 'string') {
    return new Handlebars.SafeString(returnItem);
  } else {
    return returnItem;
  }
}
